//
//
//
//
//
//
//
//


export default {
  name: 'empty_layout',

  data () {
    return {

    }
  },
  mounted(){
  }
}
